
















import { Component, Prop, Vue } from "vue-property-decorator";
//  components
import DataQualityIndicator from "@/components/dataQuality/DataQualityIndicator.vue";
import DataIndicator from "./DataIndicator.vue";
//  types
import { CellData, IndicatorData } from "@/types/dataQuality/dataQualityTable";

@Component({
  components: {
    DataIndicator,
    DataQualityIndicator,
  },
})
export default class DataCell extends Vue {
  @Prop({ type: Object }) data!: CellData;

  //  @Getters
  get isIndicator(): boolean {
    if (!this.data) return false;
    return this.data.type === "dataIndicator";
  }

  get IndicatorData(): IndicatorData | undefined {
    if (!this.isIndicator) return;
    return {
      column: this.data.column,
      columnName: this.data.columnName,
      status: this.data.value,
      text: this.indicatorText,
      icon: this.indicatorIcon,
      link: null,
      align: this.data.align,
      lastDataTimestamp: this.data.lastDataTimestamp,
      outageStartTime: this.data.outageStartTime,
      outageDetectionTime: this.data.outageDetectionTime,
    };
  }

  get indicatorText(): string {
    switch (this.data.value) {
      case 1:
        return "Available";
      case 2:
        return "Observe";
      case 3:
        return "Alert";
      default:
        return "N/A";
    }
  }

  get indicatorIcon(): string {
    switch (this.data.value) {
      case 1:
        return "mdi-check-circle";
      case 2:
        return "mdi-alert-circle";
      case 3:
        return "mdi-minus-circle";
      default:
        return "mdi-close-circle";
    }
  }
}
