












































import { Vue, Component, Watch } from "vue-property-decorator";
import store from "@/store";
import dateHelper from "@/Scripts/utilities/date-helper";
//  types
import { DataQualityHeader } from "@/types/dataQuality/dataQualityTable";
//  components
import DataAvailabilityModal from "@/components/dataQuality/dataAvailabilityModal/DataAvailabilityModal.vue";
import DataAvailabilityTable from "@/components/dataQuality/DataAvailabilityTable/index.vue";
//  modules
import { getModule } from "vuex-module-decorators";
import VesselsModule from "@/store/clients/Vessels.module";
import DataQualityModule from "@/store/clients/DataQuality.module";

const Vessels = getModule(VesselsModule, store);
const DataQuality = getModule(DataQualityModule, store);

@Component({
  components: {
    DataAvailabilityTable,
    DataAvailabilityModal,
  },
})
export default class DataAvailability extends Vue {
  dialog = false;
  searchQuery = "";
  lastUpdatedTimestamp = " ";
  isSkeletonLoading = true;
  skeletonTimeout: number | null = null;

  headers: DataQualityHeader[] = [
    {
      text: "Vessel",
      type: "string",
      value: "vesselName",
      width: "260px",
      sortable: true,
      align: "left",
    },
    {
      text: "Overall Status",
      type: "dataIndicator",
      value: "overallStatus",
      sortable: false,
      tooltip: "Indicates overall data availability status of the vessel and is determined by the most critical status.",
      align: "left",
    },
  ];

  async created(): Promise<void> {
    await Promise.all([Vessels.refreshExtendedVessels(), DataQuality.refreshDataAvailability()]);
    const dataIndicatorHeaders = DataQuality.dataAvailability[0].data
      .map(indicator => ({
        text: indicator.featureName,
        type: "dataIndicator",
        value: indicator.featureName.replace(/\s/g, ""),
        width: "140px",
        align: "center",
        sortable: false,
      }))
      .filter(indicator => !this.indicatorsWithNAStatusForEachVessel.includes(indicator.text));
    this.headers = [...this.headers, ...dataIndicatorHeaders];

    this.lastUpdatedTimestamp = this.oldestDataTimestamp() ?? "";
    this.isSkeletonLoading = false;
  }

  get indicatorsWithNAStatusForEachVessel(): string[] {
    const allIndicators = DataQuality.dataAvailability.reduce((acc: any, item) => {
      item.data.forEach(indicator => {
        if (!acc[indicator.featureName]) {
          acc[indicator.featureName] = [];
        }
        acc[indicator.featureName].push(indicator.outageState);
      });
      return acc;
    }, {});

    return Object.keys(allIndicators).filter(key => allIndicators[key].every((value: any) => value === 0));
  }

  oldestDataTimestamp(): string | null {
    if (DataQuality.dataAvailability.length === 0) return null;

    const timestamps = DataQuality.dataAvailability.flatMap(item => item.data.map(indicator => indicator.outageDetectionTime)).filter(timestamp => timestamp !== null) as string[];

    if (timestamps.length === 0) return null;

    const oldestTimestamp = timestamps.reduce((oldest, current) => (new Date(current) < new Date(oldest) ? current : oldest));

    return this.getFormatedDateTimeString(oldestTimestamp);
  }

  get dataAvailability(): any {
    var dataAvailability = DataQuality.dataAvailability.map(item => {
      const indicators = item.data.reduce((acc: any, indicator) => {
        if (this.indicatorsWithNAStatusForEachVessel.includes(indicator.featureName)) return acc;
        acc[indicator.featureName.replace(/\s/g, "")] = {
          value: indicator.outageState,
          lastDataTimestamp: indicator.lastDataTimestamp,
          outageStartTime: indicator.outageStartTime,
          outageDetectionTime: indicator.outageDetectionTime,
        };
        return acc;
      }, {});
      return {
        vesselName: {
          value: item.vesselName ?? "Unknown Vessel",
          link: `/Vessel/${item.vesselId}/${encodeURIComponent(item.vesselName)}`,
        },
        overallStatus: {
          value: item.overallOutageState,
        },
        ...indicators,
      };
    });
    return Boolean(this.searchQuery !== null && this.searchQuery.trim())
      ? dataAvailability.filter(item => item.vesselName.value.toLowerCase().includes(this.searchQuery.toLowerCase()))
      : dataAvailability;
  }

  get tableLoading(): boolean {
    return DataQuality.loadingState;
  }

  @Watch("isSkeletonLoading")
  onSkeletonLoadingChange(newVal: boolean) {
    if (newVal) {
      if (this.skeletonTimeout) {
        clearTimeout(this.skeletonTimeout);
      }
      this.skeletonTimeout = window.setTimeout(() => {
        this.isSkeletonLoading = false;
      }, 4000);
    }
  }

  startSkeletonLoading(): void {
    this.isSkeletonLoading = true;
  }

  getFormatedDateTimeString(timestamp: string): string {
    return dateHelper.getFormatedDateTimeString(timestamp);
  }

  updateOldestDataTimestamp(vesselName: string, featureName: string, dataQualityRow: any): void {
    DataQuality.dataAvailability.forEach(item => {
      if (item.vesselName === vesselName) {
        item.data.forEach(indicator => {
          if (indicator.featureName === featureName) {
            indicator.outageDetectionTime = dataQualityRow.outageDetectionTime;
            indicator.lastDataTimestamp = dataQualityRow.lastDataTimestamp;
            indicator.outageStartTime = dataQualityRow.outageStartTime;
            indicator.outageState = dataQualityRow.outageState;
          }
        });

        item.overallOutageState = item.data.reduce((acc, indicator) => {
          if (indicator.outageState > acc) {
            return indicator.outageState;
          }
          return acc;
        }, 0);
      }
    });

    const updatedOldestTimeStamp = this.oldestDataTimestamp();
    if (updatedOldestTimeStamp) {
      this.lastUpdatedTimestamp = updatedOldestTimeStamp;
    }

    this.startSkeletonLoading();
  }

  closeModal(): void {
    this.dialog = false;
  }
}
