


























































import { Component, Prop, Vue } from "vue-property-decorator";
import store from "@/store";
//  utilities
import dateHelper from "Utilities/date-helper";
import moment from "moment";
//  types
import { IndicatorData } from "@/types/dataQuality/dataQualityTable";
//  modules
import { getModule } from "vuex-module-decorators";
import DataQualityModule from "@/store/clients/DataQuality.module";

const DataQuality = getModule(DataQualityModule, store);

@Component({})
export default class DataIndicator extends Vue {
  @Prop({ type: Object }) data!: IndicatorData;

  //  @Getters
  get isNotAvailable(): boolean {
    return this.data.status === 0;
  }

  get isOverallStatusColumn(): boolean {
    return this.data.column === "overallStatus";
  }

  get isTooltipDisplay(): boolean {
    return Boolean(this.data.lastDataTimestamp || this.data.outageStartTime) && !this.isOverallStatusColumn;
  }

  get status(): string {
    switch (this.data.status) {
      case 1:
        return "Available";
      case 2:
        return "Observe";
      case 3:
        return "Alert";
      default:
        return "N/A";
    }
  }

  get statusColor() {
    if (!this.data) return;
    switch (this.data.status) {
      case 1:
        return "success100";
      case 2:
        return "warning100";
      case 3:
        return "error100";
      default:
        return "darkGrey100";
    }
  }

  get tagTimeSpan(): any {
    if (!DataQuality.dataAvailabilityConfig) return null;
    const config = DataQuality.dataAvailabilityConfig.configurations.find(item => this.data.columnName === item.featureName);
    if (!config) return null;
    return {
      observeTimeSpan: this.convertToCorrectTimeUnit(config.warningTimeSeconds)[0],
      observeTimeSpanUnit: this.convertToCorrectTimeUnit(config.warningTimeSeconds)[1],
      criticalTimeSpan: this.convertToCorrectTimeUnit(config.criticalTimeSeconds)[0],
      criticalTimeSpanUnit: this.convertToCorrectTimeUnit(config.criticalTimeSeconds)[1],
    };
  }

  get tagTimeSpanByStatus(): { value: string; unit: string } | null {
    if (this.tagTimeSpan == null) return null;
    if (this.status === "Observe") {
      return { value: this.tagTimeSpan.observeTimeSpan, unit: this.tagTimeSpan.observeTimeSpanUnit };
    } else if (this.status === "Alert") {
      return { value: this.tagTimeSpan.criticalTimeSpan, unit: this.tagTimeSpan.criticalTimeSpanUnit };
    } else return null;
  }

  //  @Methods
  navigateToLink(): void {
    if (!this.data.link) throw new Error("Link is undefined");
    this.$router.push(this.data.link);
  }

  getFormatedDateTimeString(timestamp: string): string {
    return dateHelper.getFormatedDateTimeString(timestamp);
  }

  duration(timestamp: string): string {
    return dateHelper.getHumanizeDuration(timestamp, moment().valueOf());
  }

  convertToCorrectTimeUnit(numberInSeconds: number): [string, string] {
    if (numberInSeconds >= 2592000) {
      return [Math.round(numberInSeconds / 2592000).toString(), "month (s)"];
    }
    if (numberInSeconds >= 86400) {
      return [Math.round(numberInSeconds / 86400).toString(), "day (s)"];
    }
    if (numberInSeconds >= 3600) {
      return [Math.round(numberInSeconds / 3600).toString(), "hour (s)"];
    }

    return [numberInSeconds.toString(), "second (s)"];
  }
}
