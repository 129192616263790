var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"loading":_vm.loading,"items":_vm.rows,"headers":_vm.headers,"custom-sort":_vm.customSort,"items-per-page":_vm.limitPerPage,"hide-default-footer":_vm.isLessThanLimitPerPage},scopedSlots:_vm._u([_vm._l((_vm.headers),function(header){return {key:("header." + (header.value)),fn:function(ref){
var header = ref.header;
return [(!_vm.loading)?_c('div',{staticClass:"d-inline-flex"},[_c('span',{domProps:{"innerHTML":_vm._s(header.text)}}),(header.tooltip)?_c('v-tooltip',{attrs:{"bottom":"","color":"black","content-class":"with-triangle","max-width":"280"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"color":"darkGrey75","size":"medium"}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}],null,true)},[_c('span',{staticClass:"tooltip-text"},[_vm._v(_vm._s(header.tooltip))])]):_vm._e()],1):_vm._e()]}}}),{key:"item",fn:function(ref){
var item = ref.item;
return [(!_vm.loading)?_c('tr',_vm._l((_vm.headers),function(header,index){return _c('td',{key:index},[(item[header.value])?_c('DataCell',{attrs:{"data":_vm.cellData(item, header, index)}}):_vm._e()],1)}),0):_vm._e()]}},(true)?{key:"progress",fn:function(){return [_c('v-skeleton-loader',{attrs:{"type":"table-tbody","types":_vm.skeletonStructure}})]},proxy:true}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }